import React, { FC, memo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { useBottomSheetHeight } from '@ucheba/ui/components/Select/bll'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { EActivityInfoPart, EActivityTypes } from '@ucheba/utils/types/userActivityTypes'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { EFunnels } from '@ucheba/utils/types/funnels'
import { ETypeRequest } from '@ucheba/utils/types/typeRequest'
import { EProductTypes } from '@ucheba/utils/types/productTypes'
import { notificationsSelectors } from '@ucheba/ui/components/Notice/store'
import { useBannerBottomSheet } from '../bll'
import { IBannerLidForm } from '../types'
import LeadForm from '../../LeadForm/touch'
import LidMagnetForm from '../../LidMagnetForm/touch'

const ResetBottomRadiusBox = styled.div`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
`

const AdvText = styled.div`
  z-index: 999999;
  cursor: default;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
`

const TextAdv = styled(Text)`
  letter-spacing: 1px;
`

/** Компонент рекламный баннерр */
const BannerLidFormBottomSheet: FC<IBannerLidForm> = (props) => {
  const { content, pixelImg, id, bannerId, companyPixelUrl } = props
  const [isOpenBottomSheet, toggleOpenBottomSheet] = useState(false)

  const {
    text,
    url_text,
    image_url,
    heading,
    color_theme,
    overTitle,
    clientName,
    lead_note,
    lead_institution_id,
  } = content

  const bottomSheetBanner = useBottomSheetHeight({ isOpen: isOpenBottomSheet })

  useBannerBottomSheet({ content, toggleOpenBottomSheet })

  const isAuth = useSelector(authSelectors.isAuth)

  const refDate = useRef<number>(Date.now())
  const notices = useNotice(
    `leadFormBannerId${lead_institution_id}${id}${bannerId}${refDate.current}`
  )
  useSelector(notificationsSelectors.notificationsQueue)

  return (
    <>
      <BottomSheet
        open={isOpenBottomSheet}
        toggleOpen={toggleOpenBottomSheet}
        ref={bottomSheetBanner.bottomSheetRef}
        height={bottomSheetBanner.bottomSheetHeight}
        overflowForBody='visible'
      >
        <ResetBottomRadiusBox as={Box} color={color_theme} spacing='null'>
          {clientName && (
            <AdvText>
              <Tooltip
                content={<Text color='black'>{clientName}</Text>}
                position='bottom-center'
                width='300px'
              >
                <TextAdv
                  textAlign='center'
                  fontSize='xsmall1'
                  lineHeight='xsmall1'
                  color='white50'
                >
                  РЕКЛАМА
                </TextAdv>
              </Tooltip>
            </AdvText>
          )}

          {lead_institution_id && (
            <LeadForm
              institutionId={Number(lead_institution_id)}
              activityType={EActivityTypes.lead_form_request}
              leadNote={lead_note}
              infoPart={EActivityInfoPart.lead_form}
              size='small'
              title={heading}
              text={text}
              onSubmitForm={() => {
                notices.addNotice()

                toggleOpenBottomSheet(false)
              }}
              withMessengers={false}
              overTitle={overTitle}
              buttonText={url_text}
              imgUrl={image_url}
              color={color_theme as any}
            />
          )}

          {!lead_institution_id && (
            <LidMagnetForm
              funnel={EFunnels.ucheba_search_lead_form}
              requestTarget={ETypeRequest.ege_lead_request}
              productType={EProductTypes.ege}
              showInputs={!isAuth}
              size='small'
              title={heading}
              text={text}
              onSubmitForm={() => {
                if (isAuth) {
                  console.log('onSubmitForm')

                  notices.addNotice()

                  toggleOpenBottomSheet(false)
                }
              }}
              onCheckedCode={() => {
                notices.addNotice()

                toggleOpenBottomSheet(false)
              }}
              withMessengers={false}
              overTitle={overTitle}
              buttonText={url_text}
              imgUrl={image_url}
              color={color_theme as any}
            />
          )}

          {pixelImg && (
            <img
              className='adv-pixel-img'
              src={pixelImg}
              style={{
                width: '1px',
                height: '1px',
                opacity: 0,
                position: 'absolute',
                top: -9999,
                right: -9999,
              }}
              alt=''
            />
          )}

          {companyPixelUrl && (
            <img
              className='adv-companyPixel-img'
              src={companyPixelUrl}
              style={{
                width: '1px',
                height: '1px',
                opacity: 0,
                position: 'absolute',
                top: -9999,
                right: -9999,
              }}
              alt=''
            />
          )}
        </ResetBottomRadiusBox>
      </BottomSheet>

      <Notice
        color='white'
        showClose={false}
        onClose={notices.removeNotice}
        open={notices.open}
        preventAutoClose={false}
      >
        <Heading h3='Заявка успешно отправлена' spacing='null' />
      </Notice>
    </>
  )
}

export { BannerLidFormBottomSheet }
export default memo(BannerLidFormBottomSheet)
